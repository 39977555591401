<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" x-small depressed outlined icon>
        <v-icon small>mdi-help</v-icon>
        <!-- <v-icon small>mdi-lifebuoy</v-icon> -->
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t("COMMON.HELP") }}
      </v-card-title>

      <div class="pa-4">
        <Loading :value="loading" />
        <div class="html-body" v-html="htmlData" />
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{
          $t("COMMON.CLOSE")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import Loading from "@/components/Loading";

const type = {
  campaign: "explanation-type-campaign",
  title: "explanation-feed-field-title",
  description: "explanation-feed-field-description",
  url: "explanation-feed-field-url",
  location: "explanation-feed-field-location",
  profile: "explanation-feed-field-profile",
  category: "explanation-feed-field-category",
  duration: "explanation-feed-field-duration",
  contract: "explanation-feed-field-contract",
  salary: "explanation-feed-field-salary",
  quantity: "explanation-feed-field-quantity",
  age: "explanation-feed-field-age",
  education: "explanation-feed-field-education",
  experience: "explanation-feed-field-experience",
  company: "explanation-feed-field-company",
  contact: "explanation-feed-field-contact",
  date: "explanation-feed-field-date",
  start_date: "explanation-feed-field-start-date",
  end_date: "explanation-feed-field-end-date",
};

export default {
  name: "ExplanationDialog",

  components: {
    Loading,
  },

  data: () => ({
    loading: true,
    dialog: false,
    htmlData: "",
  }),

  props: {
    for: {
      type: String,
      default: "campaign",
    },
  },

  mounted() {
    this.loadContent();
  },

  methods: {
    loadContent() {
      axios
        .get(
          `${process.env.VUE_APP_FILESHARE_PUBLIC}/documentation/${
            this.$i18n.locale
          }/${type[this.for]}.html`
        )
        .then((result) => {
          this.htmlData = result.data;
        })
        .catch((response) => {
          console.log("CATCH", response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.html-body >>> header {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.html-body >>> span {
  font-size: 16px;
}
</style>