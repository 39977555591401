<template>
  <div class="form-stepper form-stepper-feed">
    <Loading :value="loading" />
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
      v-if="ready"
      @submit.prevent
    >
      <div ref="content">
        <p class="mb-0">{{ $t("FEED.FILEFORMAT_TEXT") }}</p>
        <small class="grey--text">{{ $t("FEED.FILEFORMAT_DESCRIPTION") }}</small>

        <v-row class="mt-4 mb-0">
          <v-col cols="12" class="pt-0 pb-0">
            <v-checkbox
                class="mt-0"
                v-model="createLandingPages"
                dense
                @change="setUrlAndShowPublic"
                :label="$t('INPUT.CREATE_LANDING_PAGES')"
                :placeholder="$t('INPUT.CREATE_LANDING_PAGES')"
            />
          </v-col>
        </v-row>

        <v-row class="grey--text mt-4 text-small text-uppercase">
          <v-col cols="3">{{ $t('FEED.HEADER_KEY_NAME') }}</v-col>
          <v-col cols="9">{{ $t('FEED.HEADER_COLUMNS') }}</v-col>
        </v-row>
        <template v-for="(item, i) in fileformat_fields_base">
          <div v-if="fileformat_fields_form[i]" :key="'ffb'+i">
            <v-row
              v-if="item.separator"
              class="grey--text mt-4 text-small text-uppercase"
              >
              <v-col cols="6">{{ $t('FEED.HEADER_KEY_' + item.key.toUpperCase()) }}</v-col>
            </v-row>
            <v-row
              v-else-if="!item.custom"
              class="mt-0"
            >
              <v-col cols="3" class="pr-0">
                <div v-if="!item.custom" class="pt-2">
                  {{ $t('INPUT.' + fileformat_fields_form[i].key.toUpperCase()) }}
                  <span class="red--text" v-if="item.required">*</span>
                </div>
              </v-col>
              <v-col cols="7">
                <v-combobox
                  small-chips
                  clearable
                  multiple
                  dense
                  :disabled="(item.key === 'url' || item.key === 'show_public') && createLandingPages"
                  hide-details
                  :rules="isValidFields(i) || !item.required ? [true] : [$rules.required]"
                  :placeholder="$t('INPUT.MULTIPLE_VALUE')"
                  :items="columns"
                  v-model="fileformat_fields_form[i].value"
                  outlined
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content class="form-stepper-feed__list-item-fields">
                      {{ item }}
                    </v-list-item-content>
                    <v-spacer></v-spacer>
                    <v-list-item-action>
                      <v-menu max-width="400px" max-height="500px" open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon>mdi-message-question-outline</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-subtitle class="pb-0">
                            {{ $t("FEED.FIELD_FILEFORMAT_EXAMPLES") }} ({{ item }})
                          </v-card-subtitle>
                          <template v-for="(example, index) in itemExamples(item)">
                            <v-card-text
                              v-if="example !== ''"
                              :key="`ex${index}`"
                              class="pt-0"
                            >
                              <v-divider class="mt-2 mb-2" />
                              " {{ example }} "
                            </v-card-text>
                            <v-card-text
                              v-else-if="itemExamples(item).length === 1 && example === ''"
                              :key="`ex${index}`"
                              class="pt-0"
                            >
                              <v-divider class="mt-2 mb-2" />
                              {{ `" ${$t('COMMON.NO_EXAMPLE')}"` }}
                            </v-card-text>
                          </template>
                        </v-card>
                      </v-menu>
                    </v-list-item-action>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="2" class="pr-0 pl-0 text-right position-relative" :key="updateKey">
                <v-badge
                  bordered
                  color="info"
                  dot
                  overlap
                  :value="hasSettings(i)"
                  >
                    <v-btn
                      icon
                      @click="showAdvancedDialog(i)"
                    >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </v-badge>
                <v-dialog
                  :ref="'menu_changes_'+i"
                  :value="dialog_advanced[i]"
                  persistent
                  overlay-color="transparent"
                  :eager="true"
                  max-width="650px"
                  min-width="650px"
                >
                  <v-card
                    width="650px"
                    max-height="75vh"
                    class="scroll"
                    outlined
                  >
                    <v-toolbar large flat color="transparent">
                      <v-toolbar-title style="max-width:150px;">
                        <v-icon class="mr-2" size="20">mdi-cog</v-icon>{{$t('INPUT.' + fileformat_fields_form[i].key.toUpperCase())}}
                      </v-toolbar-title>
                      <v-spacer />
                      <div class="mr-6">
                        <v-btn-toggle v-model="example_format" mandatory>
                          <v-btn small depressed value="input" title="Text w/o change">FILE</v-btn>
                          <v-btn small depressed value="output" title="Text with changes">TEXT</v-btn>
                          <v-btn small depressed class="mr-2" value="output_html" title="Html with changes">HTML</v-btn>
                        </v-btn-toggle>
                      </div>
                      <div class="width-30 mr-6">
                        <v-select dense v-model="tab" :items="examples.map((v,i) => ({text:$t('COMMON.SAMPLE') + ' ' + (i+1), value:i}))" outlined hide-details :placeholder="$t('COMMON.SAMPLE')" />
                      </div>
                      <v-btn fab small color="success" depressed @click="closeAdvancedDialog(i)"><v-icon>mdi-check</v-icon></v-btn>
                    </v-toolbar>
                    <v-divider />
                    <div class="scroll yellow lighten-4" style="height:35vh; max-height:35vh;">
                      <div :key="exampleKey" >
                        <v-tabs-items v-model="tab">
                          <v-tab-item
                          transition="none"
                          v-for="(ex,j) in examples"
                          :key="'tabexx'+i+j"
                          class="yellow lighten-4"
                          >
                            <div class="pr-10 pl-10 pa-4" style="opacity:0.7; font-size:80%; font-family: mono;" v-if="example_format === 'input'" >
                              <i v-text="exampleText(fileformat_fields_form[i], ex)" />
                            </div>
                            <div class="pr-10 pl-10 pa-4" style="opacity:0.9; font-size:80%; font-family: mono;" v-if="example_format === 'output'" >
                              <div v-text="applyChanges(exampleText(fileformat_fields_form[i], ex), fileformat_fields_form[i].changes)" />
                            </div>
                            <div class="pr-10 pl-10 pa-4" style="opacity:0.9; font-size:90%;" v-if="example_format === 'output_html'">
                              <div v-html="toHtml(applyChanges(exampleText(fileformat_fields_form[i], ex), fileformat_fields_form[i].changes))" />
                            </div>
                          </v-tab-item>
                        </v-tabs-items>
                      </div>
                    </div>
                    <v-divider />
                    <v-tabs dense height="35px" v-model="tab2" background-color="secondary">
                      <v-tab>
                        {{ $t('INPUT.VALUES') }}
                      </v-tab>
                      <v-tab>
                        {{ $t('FEED.FIELD_FILEFORMAT_CHANGES') }}
                      </v-tab>
                      <v-spacer />
                    </v-tabs>
                    <div class="scroll" style="height:24vh; max-height:24vh;">
                      <v-tabs-items v-model="tab2">

                        <v-tab-item transition="none" class="pa-6">
                          <v-row class="mt-0 mb-0">
                            <v-col cols="3" class="pr-0">
                              <div class="pt-2">
                                {{ $t('INPUT.VALUES') }}
                              </div>
                            </v-col>
                            <v-col cols="9">
                              <v-combobox
                                small-chips
                                clearable
                                multiple
                                dense
                                hide-details
                                :rules="isValidFields(i) || !item.required ? [true] : [$rules.required]"
                                :placeholder="$t('INPUT.MULTIPLE_VALUE')"
                                :items="columns"
                                v-model="fileformat_fields_form[i].value"
                                outlined
                              >
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    {{ item }}
                                  </v-list-item-content>
                                  <v-spacer></v-spacer>
                                  <v-list-item-action>

                                    <v-menu max-width="400px" max-height="500px" open-on-hover>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" icon>
                                          <v-icon>mdi-message-question-outline</v-icon>
                                        </v-btn>
                                      </template>
                                      <v-card>
                                        <v-card-subtitle>{{ $t("FEED.FIELD_FILEFORMAT_EXAMPLES") }} ({{ item }})</v-card-subtitle>
                                        <template v-for="(example, index) in itemExamples(item)">
                                          <v-card-text
                                            v-if="example !== ''"
                                            :key="`ex${index}`"
                                            class="pt-0"
                                          >
                                            <v-divider class="mt-2 mb-2" />
                                            " {{ example }} "
                                          </v-card-text>
                                          <v-card-text
                                            v-else-if="index === 0"
                                            :key="`ex${index}`"
                                            class="pt-0"
                                          >
                                            <v-divider class="mt-2 mb-2" />
                                            {{ `" ${$t('COMMON.NO_EXAMPLE')}"` }}
                                          </v-card-text>
                                        </template>
                                      </v-card>
                                    </v-menu>

                                  </v-list-item-action>
                                </template>
                              </v-combobox>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0 mb-0">
                            <v-col cols="3" class="pt-0">
                              <div class="pt-2 text-nowrap text-ellipsis">
                                {{ $t('FEED.FIELD_FILEFORMAT_DEFAULT_VALUE') }}
                              </div>
                            </v-col>
                            <v-col cols="9" class="pt-0">
                              <v-text-field
                              dense
                              hide-details
                              :placeholder="$t('FEED.FIELD_FILEFORMAT_DEFAULT_VALUE')"
                              v-model="fileformat_fields_form[i].default_value"
                              outlined
                              />
                            </v-col>
                          </v-row>
                          <v-row class="mt-0 mb-0">
                            <v-col cols="3" class="pt-0">
                              <div class="pt-2 text-nowrap text-ellipsis">
                                {{ $t('FEED.FIELD_FILEFORMAT_EXCLUDE_VALUE') }}
                              </div>
                            </v-col>
                            <v-col cols="9" class="pt-0">
                              <v-autocomplete
                              v-model="fileformat_fields_form[i].excludes"
                              :items="fileformat_fields_form[i].excludes"
                              :label="$t('FEED.FIELD_FILEFORMAT_EXCLUDE_VALUE')"
                              :placeholder="$t('FEED.FIELD_FILEFORMAT_EXCLUDE_VALUE')"
                              clearable
                              outlined
                              dense
                              hide-details
                              />
                            </v-col>
                          </v-row>
                          <v-row class="mt-0 mb-0">
                            <v-col cols="3" class="pt-0">
                              <div class="pt-2 text-nowrap text-ellipsis">
                                {{$t('FEED.FIELD_FILEFORMAT_SPACING')}}
                              </div>
                            </v-col>
                            <v-col cols="3" class="pt-0">
                              <v-text-field
                              v-model="fileformat_fields_form[i].spacing"
                              :label="$t('FEED.FIELD_FILEFORMAT_SPACING')"
                              :placeholder="$t('FEED.FIELD_FILEFORMAT_SPACING')"
                              clearable
                              outlined
                              dense
                              hide-details
                              />
                            </v-col>
                            <v-col cols="6" class="pt-0">
                              <div class="pt-2 text-nowrap text-ellipsis">
                                {{ $t('FEED.FIELD_FILEFORMAT_SPACING_DEFAULT_VALUE') }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-tab-item>

                        <v-tab-item transition="none">
                          <v-row class="ma-0">
                            <v-col cols="12" class="pa-4">
                              <v-btn
                                small
                                depressed
                                rounded
                                color="secondary black--text"
                                @click="addChanges(i)"
                              >
                                <v-icon class="mr-2">mdi-plus</v-icon>
                                {{ $t('FEED.FIELD_FILEFORMAT_CHANGES_BTN') }}
                              </v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="(change, j) in fileformat_fields_form[i].changes">
                            <v-divider :key="'d'+j" class="mb-3" />
                            <v-row :key="'ch'+j" class="mt-0 mb-0 mr-4">
                              <v-col cols="3" class="pt-0 text-right">
                                <v-chip style="width:32px; justify-content: center;" class="mr-4">{{j+1}}</v-chip>
                                <v-btn
                                  :disabled="!changesCanUp(i,j)"
                                  @click="uppperChanges(i,j)"
                                  icon
                                  class="ml-2"
                                  small
                                >
                                  <v-icon size="18">mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn
                                  :disabled="!changesCanLow(i,j)"
                                  @click="lowerChanges(i,j)"
                                  icon
                                  class="ml-2"
                                  small
                                >
                                  <v-icon size="18">mdi-arrow-down</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="3" class="pt-0">
                                <v-select
                                  v-model="fileformat_fields_form[i].changes[j].name"
                                  :items="Object.keys(change_functions)"
                                  :label="$t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION')"
                                  :rules="[$rules.required]"
                                  required
                                  hide-details
                                  outlined
                                  dense
                                  @change="
                                    name => fileformat_fields_form[i].changes[j].args= new Array(change_functions[name].args.length).fill(null)
                                    && updateChangesRender()
                                  "
                                />
                              </v-col>
                              <v-col cols="6" class="pt-0 text-right pr-0">
                                <v-btn
                                  icon
                                  @click="removeChanges(i,j)"
                                  class="mr-2"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="3"/>
                              <v-col
                                cols="5"
                                class="pt-0 pr-1"
                                v-if="change_functions[fileformat_fields_form[i].changes[j].name].args[0]"
                              >
                                <v-text-field
                                  v-model="fileformat_fields_form[i].changes[j].args[0]"
                                  :label="
                                    $t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_'
                                    + change_functions[fileformat_fields_form[i].changes[j].name].args[0].toUpperCase())
                                  "
                                  :placeholder="
                                    $t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_'
                                    + change_functions[fileformat_fields_form[i].changes[j].name].args[0].toUpperCase())
                                  "
                                  hide-details
                                  clearable
                                  outlined
                                  dense
                                  @change="updateChangesRender"
                                />
                              </v-col>
                              <v-col
                                cols="4"
                                class="pl-0 pt-0"
                                v-if="change_functions[fileformat_fields_form[i].changes[j].name].args[1]"
                              >
                                <v-text-field
                                  v-model="fileformat_fields_form[i].changes[j].args[1]"
                                  :label="
                                    $t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_'
                                    + change_functions[fileformat_fields_form[i].changes[j].name].args[1].toUpperCase())
                                  "
                                  :placeholder="
                                    $t('FEED.FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_'
                                    + change_functions[fileformat_fields_form[i].changes[j].name].args[1].toUpperCase())
                                  "
                                  hide-details
                                  clearable
                                  outlined
                                  dense
                                  @change="updateChangesRender"
                                />
                              </v-col>
                            </v-row>
                          </template>
                        </v-tab-item>
                      </v-tabs-items>
                    </div>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </div>
        </template>
      </div>
    </v-form>
    <v-divider />
    <v-toolbar color="transparent" flat height="70px" class="form-footer">
      <v-spacer />
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t("COMMON.CONTINUE") }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
const default_fields = [
  {key: 'ref', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: [], required:true},
  {key: 'title', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: [], required:true},
  {key: 'description', value: [], default_value: undefined, spacing: '<br />', changes: [], excludes: [], required:true},
  {key: 'profile', value: [], default_value: undefined, spacing: '<br />', changes: [], excludes: []},
  {key: 'url', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'date', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'location', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'duration', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'contract', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'contract_start_date', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'contract_end_date', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'salary', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},

  {key: 'separator_contact', separator: true, },
  {key: 'contact_name', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'contact_email', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},

  {key: 'separator_company', separator: true, },
  {key: 'company_title', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'company_description', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'company_image_url', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'company_url', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},

  {key: 'separator_advanced', separator: true, },
  {key: 'show_public', value: [], default_value: 'false', spacing: undefined, changes: [], excludes: []},
  {key: 'quantity', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'age', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'education', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'experience', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'start_date', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []},
  {key: 'end_date', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: []}
]

const _change_functions = {
  'extract': {name: 'extract', args:['value']},
  'replace': {name: 'replace', args:['value', 'replace']},
  'prepend': {name: 'prepend', args:['value']},
  'append': {name: 'append', args:['value']},
}


import Loading from "@/components/Loading.vue";
import ExplanationDialog from "@/components/ExplanationDialog";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "FormFeedStepperFields",
  components: {
    Loading,
    ExplanationDialog,
  },
  data: (_this) => ({
    loading: false,
    valid: false,
    ready: false,
    createLandingPages: false,
    change_functions: _this.$clone(_change_functions),
    custom_field: {key: '', value: [], default_value: undefined, spacing: undefined, changes: [], excludes: [], custom: true},
    selectedSettings: -1,
    updateKey: 0,
    dialog: false,
    dialog_advanced: new Array(100).fill(0).map(v => false),
    fileformat_fields_base: _this.$clone(default_fields),
    fileformat_fields_form: _this.$clone(default_fields),
    tab: 0,
    tab2: 0,
    //
    fileformat: undefined,
    fileformat_list: undefined,
    fileformat_fields: _this.$clone(default_fields),
    fileformat_columns: [],
    examples: [],
    example_format: 'output',
    exampleKey:0,
    count: 0,
    notUseExample: [
      'salary_currency',
      'salary_period',
      'location_city_code',
      'location_department_code',
      'contract_schedule',
      'contract_status',
      'contract_remote',
      'salary_min',
    ],
  }),
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    examplesJob () {
      return this.examples.map(example => this.convertObj(example).shift()) || []
    },
    columns () {
      return this.values.fileformat_columns || []
    },
    filledFieldsCount () {
      return this.fileformat_fields
        .filter(v => v.custom && ((v.value && v.value.length) || v.default_value)).length
    },
    filledFieldsFormCount () {
      return this.fileformat_fields_form
        .filter(v => v.custom && ((v.value && v.value.length) || v.default_value)).length
    },
    filledFieldsFormLimit () {
      return this.fileformat_fields_form.filter(v => v.custom).length
    },
  },
  watch: {
    values: {
      handler() {
        this.attribValues()
      },
      deep: true,
    },
    examples: {
      handler(val) {
        if (val.length > 0) {
          this.addExamplesJob(val)
        }
      },
      deep: true,
    },
  },
  mounted () {
    this.attribValues()
  },
  methods: {
    ...mapActions({
      addExamplesJob: 'job/addExamplesJob',
    }),
    // Simulate showndown html cleaning
    toHtml (str = '') {
      str = this.$showdown.makeMarkdown(str)
      str = this.$showdown.makeHtml(str)
      return str
    },

    convertObj(obj) {
      let changedObj = {}
      let skippedKey = ''
      Object.keys(obj).forEach(key => {
        if (!Array.isArray(obj[key])) {
          changedObj[key] = obj[key]
          return
        }
        if (typeof obj[key][0] === 'string') {
          changedObj[key] = obj[key].map(val => val.trim()).join(', ')
          return
        }
        const [
          convertedObj,
          receivedSkipedKey,
        ] = this.convertObj(obj[key][0])
        changedObj = {
          ...changedObj,
          ...convertedObj,
        }
        skippedKey = key
        changedObj[key] = Object.entries(convertedObj)
          .filter(([k, v]) => v.trim() !== '' && k !== receivedSkipedKey)
          .map(([, v]) => v)
          .join(', ')
      })
      return [
        changedObj,
        skippedKey,
      ]
    },

    applyChanges(value = '', changes = []) {
      // console.log("applyChanges") //value, changes)
      for (let j = 0, len = changes.length; j < len; j++) {
        const change = changes[j]
        if (change.name && change.args && change.args.length) switch (change.name) {
          case 'extract': {
            const reg = new RegExp(change.args[0], 'gim')
            const m = reg.exec(value)
            value = ''
            if (m && m.length > 1) {
              value = m.slice(1).map(v => v ? v.trim() : v).filter(v => v || v === 0).join(', ')
            }
            break
          }
          case 'replace': {
            value = value.replace(new RegExp(change.args[0], 'gmi'), (change.args[1] || ''))
            break
          }
          case 'prepend': {
            value = (change.args[0] || '') + value
            break
          }
          case 'append': {
            value = value + (change.args[0] || '')
            break
          }
        }
      }
      return value
    },

    exampleText (item, example) {
      const getChildValue = (v) => {
        if (v && v['_']) return v['_']
        return v
      }
      let values = item.value || []
      if (!(values instanceof Array)) values = [values]
      values = values.map(key => {
        if (key) {
          let value = this.$nestedData(key, example, '', true)
          if (value && item.excludes.indexOf(value) === -1) {
            return getChildValue(value)
          }
        }
      })
      values = values.filter(v => v || v === 0)
      values = values.join(item.spacing || '')
      values = (values ? values.trim() : item.default_value) || ''
      return values
    },

    showAdvancedDialog (i) {
      this.dialog_advanced[i] = true
      this.updateKey++
    },

    closeAdvancedDialog (i) {
      this.dialog_advanced[i] = false
      this.updateKey++
    },

    makeUniq(arr) {
      return [...new Set(arr)]
    },

    itemExamples(item) {
      const itemArr = item.split('.')
      const num = itemArr.length-1

      let result = []
      for (let i = 0; i < this.examplesJob.length; i++) {
        this.examplesJob[i][itemArr[num]] ?
          result.push(this.examplesJob[i][itemArr[num]]) :
          result.push('')
      }

      return this.makeUniq(result)
    },

    isValidFields (i) {
      return (
        (this.fileformat_fields_form[i].value && this.fileformat_fields_form[i].value.length > 0)
          || this.fileformat_fields_form[i].default_value
      )
    },

    hasSettings (i) {
      return this.fileformat_fields_form[i].default_value
        || this.fileformat_fields_form[i].spacing
        || this.fileformat_fields_form[i].changes.length > 0
        || this.fileformat_fields_form[i].excludes.length > 0
    },

    addField () {
      if (this.filledFieldsFormLimit < 10) {
        this.fileformat_fields_form.push(this.$clone(this.custom_field))
        setTimeout(() => {
          const content = this.$refs.content
          content.scrollTop = 1000000
        }, 50)
      }
    },
    removeField (i) {
      this.fileformat_fields_form.splice(i,1)
    },


    updateChangesRender () {
      this.exampleKey++
    },

    // changes in fields
    removeChanges (i,j) {
      this.fileformat_fields_form[i].changes.splice(j,1)
      // this.$refs[`menu_changes_${i}`][0].onResize()
      this.updateChangesRender()
    },
    addChanges (i) {
      this.fileformat_fields_form[i].changes.push({name: 'replace', args:[]})
      // this.$refs[`menu_changes_${i}`][0].onResize()
      this.updateChangesRender()
    },
    uppperChanges (i,j) {
      if (this.changesCanUp(i,j)) {
        const v = this.fileformat_fields_form[i].changes[j]
        this.fileformat_fields_form[i].changes.splice(j, 1)
        this.fileformat_fields_form[i].changes.splice(j-1, 0, v)
        this.updateChangesRender()
      }
    },
    lowerChanges (i,j) {
      if (this.changesCanLow(i,j)) {
        const v = this.fileformat_fields_form[i].changes[j]
        this.fileformat_fields_form[i].changes.splice(j, 1)
        this.fileformat_fields_form[i].changes.splice(j+1, 0, v)
        this.updateChangesRender()
      }
    },
    changesCanUp (i,j) {
      return j > 0
    },
    changesCanLow (i,j) {
      const len = this.fileformat_fields_form[i].changes
      return j < len-1
    },


    // field settings
    showSetting(i) {
      if (this.selectedSettings === i) {
        this.selectedSettings = -1
      } else {
        this.selectedSettings = i
      }
    },

    // merge fields values
    mergeFileformatFields (values, key) {
      const data = []
      if (values[key] && (values[key] instanceof Array) && this[key] && (this[key] instanceof Array)) {
        for (let i = 0, len1 = values[key].length; i < len1; i++) {
          const field1 = values[key][i]
          for (let j = 0, len2 = this[key].length; j < len2; j++) {
            const field2 = this[key][j]
            if (field1.key === field2.key) {
              this[key][j] = this.$clone(field1)
              break
            } else if (j === len2-1) {
              this[key].push(this.$clone(field1))
            }
          }
        }
      }
    },

    testFile () {
      this.ready = true
      if (!this.fileformat_list) {
        this.ready = false
        this.loading = true
        this.$services.api_programmatic.feed.test({
          values: {
            protocol: this.values.protocol,
            protocol_params: this.values.protocol_params
          }
        })
          .then(response => {
            if (response.data) {
              this.fileformat_columns = response.data.columns
              this.fileformat_list = response.data.list
              this.fileformat = response.data.format
              this.count = response.data.count
              this.examples = response.data.dataList
              this.ready = true
            }
          })
          .catch(e => {
            this.ready = false
            this.$emit('prev')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    // attrib values in data
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'fileformat_fields') {
          this.mergeFileformatFields(values, key)
        } else {
          this[key] = values[key]
        }
      })
      this.fileformat_fields_form = this.$clone(this.fileformat_fields)
      this.updateChangesRender()
      // if no list, reload file
      this.testFile()
    },

    validate() {
      if (!this.$refs.form.validate()) {
        this.$emit("validation", false);
        return;
      }

      const data = {
        fileformat: this.fileformat,
        fileformat_list: this.fileformat_list,
        fileformat_fields: this.fileformat_fields_form.filter(
          (v) => !v.separator && v.key && (v.value || v.default_value)
        ),
        fileformat_columns: this.fileformat_columns,
      };

      this.$emit("childData", data);
      this.$emit("validation", true);
      this.$emit("next");
    },

    setUrlAndShowPublic() {
      this.fileformat_fields_form.find(f => f.key === 'url').value = []
      this.fileformat_fields_form.find(f => f.key === 'show_public').default_value = this.createLandingPages.toString()
    }

  },
};
</script>

<style lang="scss">
.form-stepper-feed__list-item-fields {
  &.v-list-item__content {
    min-width: 300px;
  }
}
</style>
